(function($){
$(document).ready(function(){

  $('.filter-select').selectize({
    placeholder: 'Filter by...',
    allowEmptyOption: true,
    create: false,
  });

  $(".selectize-input input").attr('readonly','readonly');

  
  if ( $('[type="date"]').prop('type') != 'date' ) {
    $('[type="date"]').datepicker({
      dateFormat: 'yy-mm-dd',
    });
  }

  var propertySearchForm = $('#property_search');
  // Update hidden elements in form as external filters change
  $('.external-to-form').on('change', function (e) {
    var element = $(this);
    var value = element.val();
    var hiddenFieldId = element.data('hiddenFieldTarget');
    $(hiddenFieldId).val(value);
    propertySearchForm.submit();
  });

  $('#location-filter').on('change', function (e) {
    var element = $(this);
    var locationPageUrl = element.val();
    if(propertySearchForm) {
      propertySearchForm.attr('action', locationPageUrl);
      propertySearchForm.submit();
    }
  });

  var priceSlider = $('#slider-range');
  var minPrice = $('#min_price');
  var maxPrice = $('#max_price');
  if (priceSlider.length > 0) {
    priceSlider.slider({
      range: true,
      min: priceSlider.data('min'),
      max: priceSlider.data('max'),
      values: [priceSlider.data('selectedMin'), priceSlider.data('selectedMax')],
      slide: function( event, ui ) {
        updateSliderRangeText(ui.values);
      },
      stop: function( event, ui ) {
        updatePriceMinMax(ui.values);
      }
    });

    updateSliderRangeText(priceSlider.slider("values"));
  }

  function updateSliderRangeText(values) {
    var min = values[0];
    var max = values[1];
    var rangeString = "£" + min + " - £" + max;
    $('#amount').val(rangeString);
  }

  function updatePriceMinMax(values) {
    var min = values[0];
    var max = values[1];
    minPrice.val(min);
    maxPrice.val(max);
  }

  $('.enquiry-form h1').on('click', function() {
    $(this).closest('.wrapper').toggleClass('active');
  })

  $('.logo-slick').slick({
    dots: false,
    infinite: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
          arrows: true,
        }
      },
      {
        breakpoint: 440,
        settings: {
          settings: 'unslick'
        }
      }
    ]
  });

  // Book an appointment form
  $('.gfield.viewing-time select').attr('disabled','disabled');

  jQuery(document).on('gform_page_loaded', function(event, form_id, current_page){
 //   $('.gfield.viewing-time select option:not(:selected)').remove();
    let selected = $('.gfield.viewing-time select option:selected').html();
    let viewingDate = $('#input_' + form_id + '_1').val();
    if(viewingDate) {
      const data = {
        'action': 'ff_get_app_times',
        'viewingDate': viewingDate, // that's how we get params from wp_localize_script() function
      };
      $.ajax({ // you can also use $.post here
        url: viewing_params.ajaxurl, // AJAX handler
        data: data,
        type: 'POST',
        dataType: 'json',
        beforeSend: function (xhr) {
          $('.gfield.viewing-time select').attr('disabled', 'disabled');
        },
        success: function (data) {
          if (data) {
            $('.gfield.viewing-time select').html(data);
            if(selected != 'Select a time') {
              $('.gfield.viewing-time select').val(selected);
            }
          }
        },
        complete: function () {
          $('.gfield.viewing-time select').removeAttr('disabled');
        }
      });
    } else {
      $('.gfield.viewing-time select').attr('disabled','disabled');
    }
  });
  function setDatepickerPos(input, inst) {
    var rect = input.getBoundingClientRect();
    // use 'setTimeout' to prevent effect overridden by other scripts
    setTimeout(function () {
      var scrollTop = $("body").scrollTop();
      inst.dpDiv.css({ top: rect.top + input.offsetHeight + scrollTop });
    }, 0);
  }
  gform.addFilter('gform_datepicker_options_pre_init', function (optionsObj, formId, fieldId) {
    // if (formId == 1 && ($('#field_' + formId + '_' + fieldId).hasClass('viewing-date'))) {
    optionsObj.selectOtherMonths = true;

    if (formId == 1 && fieldId == 1) {
     // optionsObj.minDate = '+1 D';
      optionsObj.maxDate = '+2 W';
      optionsObj.firstDay = 1;
      let today2 = new Date();
      let todaysDay2 = today2.getDay();
      if(todaysDay2 == 5 && today2.toLocaleTimeString() > '13:00:00'){
        optionsObj.minDate = '+2 D';
      } else {
        optionsObj.minDate = '+1 D';
      }
      optionsObj.beforeShowDay = function (date) {

        // Holiday dates
       // let holidays = ['21/11/2023', '06/16/2015', '06/18/2015'];
        let holidays = viewing_params.holidays;
        // If after 6pm friday, if sat, if sun - disable the next monday
        let today = new Date();
        let todaysDay = today.getDay();
        if(todaysDay == 0 || todaysDay == 6 || todaysDay == 5 && today.toLocaleTimeString() > '18:00:00') {
          let nextMon = new Date(
              today.getFullYear(),
              today.getMonth(),
              today.getDate() + (7 + 1 - today.getDay()) % 7)
          holidays.push(nextMon.toLocaleDateString())
        }
        let disabledDays = holidays,
            currentDate = jQuery.datepicker.formatDate('dd/mm/yy', date),
            day = date.getDay();
        return [!(disabledDays.indexOf(currentDate) != -1 || day == 0)];
      };
    }
    if (formId == 1 && fieldId == 5) { // move in date
      optionsObj.firstDay = 1;
      if(viewing_params.current_property_available !== undefined){
        let available = new Date(viewing_params.current_property_available);
        let today = new Date();
        let weeksAhead = 3;
        if(available > today){
          let lastDate = new Date(viewing_params.current_property_available);
          lastDate.setDate(lastDate.getDate() + (weeksAhead * 7));
          optionsObj.maxDate = lastDate.toLocaleDateString();
          optionsObj.minDate = available.toLocaleDateString();
        } else {
          optionsObj.maxDate = '+3 W';
          optionsObj.minDate = '+1 D';
        }
      } else {
        optionsObj.maxDate = '+3 W';
        optionsObj.minDate = '+1 D';
      }
    }
    jQuery( ".datepicker" ).attr('readonly','readonly');
   // optionsObj.beforeShow = function (input, inst) { setDatepickerPos(input, inst) };
    return optionsObj;
  });

  let invalidFields = [];
  gform.addAction( 'gform_input_change', function( elem, formId, fieldId ) {
    // Viewing Times -- Update on date change
    if($('#field_' + formId + '_' + fieldId).hasClass('viewing-date')) {
      let viewingDate = $('#input_' + formId + '_' + fieldId).val();
      const data = {
        'action': 'ff_get_app_times',
        'viewingDate': viewingDate, // that's how we get params from wp_localize_script() function
      };
      $.ajax({ // you can also use $.post here
        url: viewing_params.ajaxurl, // AJAX handler
        data: data,
        type: 'POST',
        dataType: 'json',
        beforeSend: function (xhr) {
          $('.gfield.viewing-time select').attr('disabled','disabled');
        },
        success: function (data) {
          if (data) {
            $('.gfield.viewing-time select').html(data);
          }
        },
        complete: function () {
          $('.gfield.viewing-time select').removeAttr('disabled');
        }
      });
    }

    // Conditional Logic
    let fieldVal = $('#input_' + formId + '_' + fieldId).val();
    switch(fieldId){
      case '9': // Age group
          if(fieldVal === '17-20'){
            invalidFields.push(fieldId)
          } else {
            invalidFields = invalidFields.filter(item => item !== fieldId)
          }
        break;
      case '28': // Occupation status
        const occupationFailConditions = [ 'Self Employed Without Accounts', 'Agency','Unemployed'];
        let checker = (arr, target) => target.some(v => arr.includes(v));
        let applicantAge = $('#input_' + formId + '_9').val();
        if(checker(fieldVal, occupationFailConditions) || (applicantAge === '21-24' && fieldVal.includes('Full Time Student'))){
          invalidFields.push(fieldId)
        } else {
          invalidFields = invalidFields.filter(item => item !== fieldId)
        }
        break;
      case '29': // Annual Income
        if(fieldVal === 'Under £15,000'){
          invalidFields.push(fieldId)
        } else {
          invalidFields = invalidFields.filter(item => item !== fieldId)
        }
        break;
      case '32': // Moving reason
        const movingFailConditions = [ 'Sofa surfing', 'Evicted'];
        if(movingFailConditions.includes(fieldVal)){
          invalidFields.push(fieldId)
        } else {
          invalidFields = invalidFields.filter(item => item !== fieldId)
        }
        break;
      case '33': // How many people
        if(fieldVal === '2+'){
          invalidFields.push(fieldId)
        } else {
          invalidFields = invalidFields.filter(item => item !== fieldId)
        }
        break;
      case '34': // Children
      case '35': // Pets
      case '50': // Smokers
        if(fieldVal === 'Yes'){
          invalidFields.push(fieldId)
        } else {
          invalidFields = invalidFields.filter(item => item !== fieldId)
        }
        break;
      case '36': // Right to rent
        if(fieldVal === 'No'){
          invalidFields.push(fieldId)
        } else {
          invalidFields = invalidFields.filter(item => item !== fieldId)
        }
        break;
      default:
        break;
    }

    if(invalidFields.length > 0){
      $('#input_1_48').val('unsuccessful');
    } else {
      $('#input_1_48').val('');
    }
  }, 10, 3 );
  jQuery(document).on('gform_page_loaded', function(event, form_id, current_page){
    console.log('page: '+current_page);
    $('.gform_previous_button:hidden').show();
    $('.gform_next_button:hidden').show();
    switch(current_page){
      case '1':
        $('.viewing-modal-title').text('Step 1 of 3');
        gtag('event', 'booking_portal_calendar_view');
          break;
      case '2':
        $('.viewing-modal-title').text('Step 2 of 3');
        gtag('event', 'booking_portal_questions_view');
        break;
      case '4':
        $('.viewing-modal-title').text('Step 3 of 3');
        gtag('event', 'booking_portal_details_view');
        break;
      case '3':
        $('.viewing-modal-title').text('Thank you for your enquiry');
        gtag('event', 'booking_portal_failure');
        $('.gform_previous_button').hide();
        $('.gform_next_button').hide();
        break;
      default:
        break;
    }
  });
  // gform.addAction( 'gform_frontend_page_visible', function( page, formId ) {
  //   if(page.fieldId == 13 && page.isVisible === true){
  //     $('.viewing-modal-title').text('Thank you for your enquiry');
  //     $('.gform_previous_button').hide();
  //     $('.gform_next_button').hide();
  //   }
  // }, 10, 2 );

  jQuery(document).on('gform_confirmation_loaded', function(event, formId){
    $('.viewing-modal-title').before('<svg class="confirmation-icon" xmlns="http://www.w3.org/2000/svg" width="48.797" height="48.797" viewBox="0 0 48.797 48.797"><path data-name="Icon ionic-md-checkmark-circle-outline" d="m14.6 19.858-3.346 3.346 10.755 10.754 23.9-23.9-3.347-3.344-20.553 20.433Zm28.918 4.541a19.037 19.037 0 1 1-13.861-18.4l3.7-3.7A22.23 22.23 0 0 0 24.395.507a23.9 23.9 0 1 0 23.9 23.9Z" fill="#863b9c" stroke="#863b9c"/></svg>');
    $('.viewing-modal-title').html('Your appointment is <u>CONFIRMED</u>');
    gtag('event', 'booking_portal_success');
  });
  $('.single-wppf_property .cta-button.book').on('click', function(event, formId){
    gtag('event', 'book_viewing_button_click');
  });
  $('#viewingModal').on('shown.bs.modal', function () {
    gtag('event', 'booking_portal_calendar_view');
    console.log('modal visible');
  })
});

/*
    Slick Gallery
*/
  var $imagesSlider = $(".gallery-slider .gallery-slider__images>div"),
      $thumbnailsSlider = $(".gallery-slider__thumbnails>div");

    // images options
    $imagesSlider.slick({
      speed:300,
      slidesToShow:1,
      slidesToScroll:1,
      cssEase:'linear',
      fade:true,
      draggable:false,
      asNavFor:".gallery-slider__thumbnails>div",
      arrows: true,
      responsive: [
        {
          breakpoint: 991,
            settings: {
              // arrows: false,
            }
          },
        ]
    });
    // thumbnails options
    $thumbnailsSlider.slick({
      speed:300,
      slidesToShow:8,
      slidesToScroll:1,
      cssEase:'linear',
      infinite: false,
      centerMode:false,
      draggable:false,
      focusOnSelect:true,
      asNavFor:".gallery-slider .gallery-slider__images>div",
      responsive: [
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 7,
            slidesToScroll: 4
          }
        },
      ]
    });


// Scroll To
$('.scroll a[href^="#"]').on('click', function(event) {
    var target = $(this.getAttribute('href'));
    if( target.length ) {
        event.preventDefault();
        $('html, body').stop().animate({
            scrollTop: target.offset().top
        }, 750);
    }
});


// Slick Sliders
$('.latest-property-slider').slick({
  dots: true,
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 3,
  prevArrow: '<span class="pe-7s-angle-left parent-arrow arrow-left"></span>',
  nextArrow: '<span class="pe-7s-angle-right parent-arrow arrow-right"></span>',
  responsive: [
  {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
    ]
});

$('.image-slider').slick({
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  lazyLoad: 'ondemand',
  nextArrow: '<i class="pe-7s-angle-right arrow-right"></i>',
  prevArrow: '<i class="pe-7s-angle-left arrow-left"></i>',
  draggable: false,
  swipeToSlide: false,
  touchMove: false,
});






// Slideout
var slideout = new Slideout({
  'panel': document.getElementById('panel'),
  'menu': document.getElementById('menu'),
  'padding': 256,
  'tolerance': 70
});

slideout.disableTouch();


// Toggle button
document.querySelector('.toggle-button').addEventListener('click', function() {
  $('#menu').toggleClass('open');
  slideout.toggle();
});

document.querySelector('.close-button').addEventListener('click', function() {
  $('#menu').toggleClass('open');
  slideout.toggle();
});

// $('form').on('submit', function(e){
//   console.log('testing');
//   var email = $('input.wpcf7-email').val();
//   var tel = $('input.wpcf7-tel').val();
//   if ( email == ''  && tel == '' )
//     {
//       $('input.wpcf7-tel').addClass('not-valid');
//       $('.not-valid-tip').text("This or Below field is Required");
//       $('.not-valid').focus();
//         return false;
//       }
//     else if( email != ''  || tel != '' )
//       {
//         $('.not-valid-tip').text("");
//       }
//     else
//       {}
//     });

$('#tenant-video').click(function(){
  video = '<iframe src="'+ $(this).attr('data-video') +'"></iframe>';
  $(this).replaceWith(video);
});


})(jQuery);
